// getLiveStreamData.js
'use strict';
import { Collection } from 'immutable';
import createCachedSelector from '../resource/createCachedSelector.js';

const defaultSelectPath = [];
const defaultDataKey = '';

/**
 * Select live stream data by live stream
 * @kind selector
 * @param {Immutable.Map} state - root state.
 * @param {array} [selectPath = []] - select path. default is [].
 * @param {string} [dataKey = ''] - data key. default is ''.
 * @return {any} The selected live stream data.
 */
const getLiveStreamData = createCachedSelector(
  (state, selectPath = defaultSelectPath) =>
    state.getIn(['liveStream', ...selectPath]),
  (state, selectPath, dataKey) => dataKey,
  (liveStream, dataKey = defaultDataKey) => {
    let result = liveStream;
    if (dataKey) {
      result = liveStream?.get(dataKey);
    }
    return result instanceof Collection ? result.toJS() : result;
  }
)(
  (state, selectPath = defaultSelectPath, dataKey = defaultDataKey) =>
    `${selectPath.join(':')}:${dataKey}`
);

export default getLiveStreamData;
