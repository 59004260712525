// userStatusIndicatorConstants.js
'use strict';

export const LIVESTREAM_TYPE_LIVE_BADGE = 'livestream-badge';
export const LIVESTREAM_TYPE_STATUS_BADGE = 'livestream-status-badge';
export const LIVESTREAM_TYPE_DOT = 'livestream-dot';
export const ONLINE_TYPE_PROFILE_DOT = 'online-profile-dot';
export const ONLINE_TYPE_PROFILE_MOBILE_DOT = 'online-profile-mobile-dot';
export const ONLINE_TYPE_DOT = 'online-dot';
export const ONLINE_TYPE_BIGGER_DOT = 'online-bigger-dot';
export const ONLINE_TYPE_BIGGER_DOT_WITH_BLACK_BORDER =
  'online-bigger-dot-with-black-border';
